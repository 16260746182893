import PropTypes from "prop-types"
import Logo from "../assets/redwerks-logo.svg"

const Header = ({ siteTitle }) => (
  <header className="flex flex-row items-center h-14 bg-white">
    <Logo className="mx-[14px]" />

    <h1 className="text-xl font-medium">{siteTitle}</h1>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
